import { Flex, Heading } from "@chakra-ui/react";

function ErrorPage() {
  return (
    <Flex>
      <Heading>Page not found!</Heading>
    </Flex>
  );
}

export default ErrorPage;
